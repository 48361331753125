<template>
  <div style="height: 100%;background: #fff;">
    <van-nav-bar
      :title="carNo + '工单追踪'"
      :z-index="999"
      :fixed="true"
      left-arrow
      @click-left="back"
    />
          <div class="shigong">
        <div class="left shigs">施工流程:</div>
        <div class="left hengs">
          <div class="left zuishu">
            <img class="lidian" src="../../assets/img/liudian.png" alt />
            <div class="jes">接车</div>
          </div>
          <div class="left zuiy" :class="status >= 2?'zuishu':'dianz'">
            <img class="lidian" src="../../assets/img/liudian.png" alt />
            <div class="jes">报价</div>
          </div>
          <div class="left" :class="[status > 3?'zuishu':'dianz',status >= 2?'zuiy':'zuid']">
            <img class="lidian" src="../../assets/img/liudian.png" alt />
            <div class="jes">施工</div>
          </div>
          <div class="left" :class="[status > 6?'zuishu':'dianz',status >= 4?'zuiy':'zuid']">
            <img class="lidian" src="../../assets/img/liudian.png" alt />
            <div class="jes">质检</div>
          </div>
          <div class="left" :class="[status > 6?'zuishu':'dianz',status > 6?'zuiy':'zuid']">
            <img class="lidian" src="../../assets/img/liudian.png" alt />
            <div class="jes">结算</div>
          </div>
          <div class="left zuishuv" :class="[status >  6?'zuiy':'zuid']">
            <img class="lidian" src="../../assets/img/liudian.png" alt />
            <div class="jes">出厂</div>
          </div>
          <!-- <div class="hengff"></div> -->
        </div>

        <!-- <van-steps :active="6" active-icon="success" active-color="red">
          <van-step>接车</van-step>
          <van-step>报价</van-step>
          <van-step>施工</van-step>
          <van-step>质检</van-step>
          <van-step>结算</van-step>
          <van-step>出厂</van-step>
        </van-steps>-->
      </div>
    <div  class="hedsst">

      <van-steps direction="vertical" :active="status+2" active-color="#999">
        <van-step v-if="status >= 1">
          <template v-slot:inactive-icon>
            <van-icon :name="img" class="imgios" />
          </template>
          <h3>接车</h3>
          <p style="margin-top: 5px;font-size: 13px;">
            <span>接待：{{addLog?addLog.operateUserName:''}}</span>
            <!-- <span style="margin-left: 25px;">业务：{{addLog?addLog.saleMan:''}}</span> -->
          </p>
          <div class="shijian">{{addLog?addLog.createdDate:''}}</div>
        </van-step>
        <van-step v-if="status >= 1">
          <template v-slot:inactive-icon>
            <van-icon :name="img2" class="imgios" />
          </template>
          <h3>报价</h3>
          <p class="baojia">
            <span v-if="sendCustomerLog.item">{{askPriceLog?askPriceLog.remark:''}}</span>
            <span class="zhijian">{{askPriceLog?askPriceLog.operateUserName:''}}</span>
          </p>
          <p class="baojia2">{{askPriceLog?askPriceLog.createdDate:""}}</p>
          <p class="baojia" v-if="sendCustomerLog.operateUserName">
            {{sendCustomerLog?sendCustomerLog.remark:''}}
            <span
              class="zhijian"
            >{{sendCustomerLog?sendCustomerLog.operateUserName:''}}</span>
          </p>
          <p class="baojia2">{{sendCustomerLog?sendCustomerLog.createdDate:''}}</p>
          <p class="baojia">
            <span class="zhijian">{{customerSignLog?customerSignLog.operateUserName:""}}</span>
          </p>
          <p class="baojia2">{{customerSignLog?customerSignLog.createdDate:''}}</p>
        </van-step>
        <van-step v-if="status >= 1">
          <template v-slot:inactive-icon>
            <van-icon :name="img3" class="imgios" />
          </template>
          <h3>施工</h3>
          <!-- <p class="namett" v-if="workingBJLog">钣金</p> -->
          <div class="list" v-for="item in workingBJLog" :key="item.itemId">
            <div class="list_name" v-if="item.operateType == 4">
              <!-- <span class="shu"></span> -->
              <span class="ccname">-------{{item.item}}</span>
              <img src="../../assets/img/goushigong.png" class="gouimg" alt="">
            </div>
            <!-- <div v-if="item.operateType == 4" class="buyi">
              工单施工
              <span class="mlf">{{item.createdDate}}</span>
            </div> -->
            <div v-if="item.operateType == 5" class="buyi">施工完成</div>
          </div>
          <!-- <p class="namett" v-if="workingJXLog">机修</p> -->
          <div class="list" v-for="(item,i) in workingJXLog" :key="i">
            <div class="list_name" v-if="item.operateType == 4">
              <!-- <span class="shu"></span> -->
              <span class="ccname">-------{{item.item}}</span>
            </div>
            <!-- <div v-if="item.operateType == 4" class="buyi">
              工单施工
              <span class="mlf">{{item.createdDate}}</span>
            </div> -->
            <div v-if="item.operateType == 5" class="buyi">施工完成</div>
          </div>
          <!-- <p class="namett" v-if="workingPQLog">喷漆</p> -->
          <div class="list" v-for="(item,idx) in workingPQLog" :key="idx">
            <div class="list_name" v-if="item.operateType == 4">
              <!-- <span class="shu"></span> -->
              <span class="ccname">-------{{item.item}}</span>
            </div>
            <!-- <div v-if="item.operateType == 4" class="buyi">
              工单施工
              <span class="mlf">{{item.createdDate}}</span>
            </div> -->
            <div v-if="item.operateType == 5" class="buyi">施工完成</div>
          </div>
          <!-- <p class="namett" v-if="workingMRLog">美容</p> -->
          <div class="list" v-for="(item,index) in workingMRLog" :key="index">
            <div class="list_name" v-if="item.operateType == 4">
              <!-- <span class="shu"></span> -->
              <span class="ccname">-------{{item.item}}</span>
            </div>

            <!-- <div v-if="item.operateType == 4" class="buyi">
              工单施工
              <span class="mlf">{{item.createdDate}}</span>
            </div> -->
            <div v-if="item.operateType == 5" class="buyi">施工完成</div>
          </div>
        </van-step>
        <van-step v-if="status >= 4">
          <template v-slot:inactive-icon>
            <van-icon :name="img4" class="imgios" />
          </template>
          <h3>质检</h3>
          <p class="baojia">
            工单质检
            <span class="zhijian">{{checkLog?checkLog.operateUserName:''}}</span>
          </p>
          <p class="baojia2">{{checkLog?checkLog.createdDate:''}}</p>
        </van-step>
        <van-step v-if="status >= 7">
          <template v-slot:inactive-icon>
            <van-icon :name="img5" class="imgios" />
          </template>
          <h3>结算</h3>
          <p class="baojia">
            工单结算
            <span class="zhijian">{{settleLog?settleLog.operateUserName:''}}</span>
          </p>
          <p class="baojia2">{{settleLog?settleLog.createdDate:''}}</p>
          <!-- <p class="baojia">客户签名</p> -->
        </van-step>
        <van-step v-if="status >= 7">
          <template v-slot:inactive-icon>
            <van-icon :name="img6" class="imgios" />
          </template>
          <div style="height: 12px;"></div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  data() {
    return {
      loading: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      id: this.$route.query.id,
      carNo: this.$route.query.carNo,
      status: this.$route.query.status,
      titel: "更换车型",
      addLog: {},
      askPriceLog: {},
      checkLog: {},
      img: require("../../assets/img/gongdan1.png"),
      img2: require("../../assets/img/gongdan2.png"),
      img3: require("../../assets/img/gongdan3.png"),
      img4: require("../../assets/img/gongdan4.png"),
      img5: require("../../assets/img/gongdan5.png"),
      img6: require("../../assets/img/gongdan6.png"),
      customerSignLog: {},
      sendCustomerLog: {},
      settleLog: {},
      workingBJLog: {},
      workingJXLog: [],
      workingMRLog: [],
      workingPQLog: []
    };
  },

  methods: {
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .getOrderLogNew({ oid: this.id })
        .then(e => {
          loading.clear();
          console.log(e.data);
          this.list = e.data;
          this.addLog = e.data.addLog;
          this.askPriceLog = e.data.askPriceLog;
          this.customerSignLog = e.data.customerSignLog;
          this.sendCustomerLog = e.data.sendCustomerLog;
          this.settleLog = e.data.settleLog;
          this.workingBJLog = e.data.workingBJLog;
          this.workingJXLog = e.data.workingJXLog;
          this.workingMRLog = e.data.workingMRLog;
          this.workingPQLog = e.data.workingPQLog;
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });
    }
  },
  created() {
    if (this.$route.query.titel != undefined) {
      this.titel = this.$route.query.titel;
    }
    this.onLoad();
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
h3 {
  font-size: 16px;
}
p {
  line-height: 26px;
}
.namett {
  display: inline-block;
  color: #fff;
  background: #07c160;
  padding: 0 4px;
  line-height: 22px;
  margin-left: 10px;
  margin-top: 15px;
}
.list {
  overflow: hidden;
}
.list_name {
  color: #666;
  line-height: 30px;
  height: 30px;
  margin: 10px 0px;
}
.baojia {
  margin-top: 12px;
  color: #666;
  font-size: 12px;
}
.baojia2 {
  margin-top: 5px;
  color: #666;
  font-size: 12px;
}
.zhijian {
  color: #000;
  margin-left: 10px;
}
.shu {
  float: left;
  width: 3px;
  background: #07c160;
  height: 30px;
}
.shijian {
  font-size: 12px;
  padding-left: 46px;
  color: #666;
}
.ccname {
  display: inline-block;
  line-height: 30px;
  // margin-left: 10px;
  vertical-align: middle;
  font-size: 15px;
  color: #333;
}
.buyi {
  color: #666;
  font-size: 12px;
  padding-left: 46px;
  margin-top: 15px;
}
.imgios .van-icon__image {
  width: 30px;
  height: 30px;
  z-index: 9;
  background: #fff;
}
.van-steps {
  padding-top: 10px;
  padding-left:20px;
  margin-left: 5px;
}
h3{
  padding-left:24px;
}
.gouimg{
  vertical-align: middle;
  width: 16px;
  margin-left: 12px;
}
.van-step__title p {
  padding-left:46px;
}
.van-step {
  // padding-left: 7px;
}
.shigong {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 46px;
  background: #fff;
  padding-top: 8px;
  z-index:9;
  // height: 45px;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 6px solid #f5f5f5;
  height: 60px;
  z-index: 9;
}
.mlf {
  margin-left: 15px;
}
.lidian {
  width: 15px;
  display: block;
  margin: auto;
  margin-top: 4px;
  z-index: 99;
}
.jes {
  line-height: 20px;
  font-size: 13px;
}
.zuishu {
  width: 50px;
  text-align: center;
  position: relative;
}
.zuishuv {
  width: 48px;
  text-align: center;
  position: relative;
}
.hedsst{
 padding-top: 106px;
 padding-bottom: 30px
}
.shigs {
  margin-top: 2px;
  margin-left: 10px;
}
.hengs {
  overflow: hidden;
  position: relative;
}
.hengff {
  height: 0.5px;
  width: 80%;
  background: #ff5467;
  position: absolute;
  left: 50%;
  margin-left: -40%;
  top: 11px;
}
.zuishu:after {
  content: "";
  height: 0.5px;
  width: 18px;
  background: #ff5467;
  position: absolute;
  right: 0;
  // left: 50%;
  // margin-left: -40%;
  top: 11px;
}
.zuiy:before {
  content: "";
  height: 0.5px;
  width: 18px;
  background: #ff5467;
  position: absolute;
  left: 0;
  // left: 50%;
  // margin-left: -40%;
  top: 11px;
}
.dianz {
  width: 50px;
  text-align: center;
  position: relative;
}
.dianz:after {
  content: "";
  height: 0.5px;
  width: 18px;
  // background: #ff5467;
  position: absolute;
  right: 0;
  border-top: 1px dashed #ff5467;
  top: 11px;
}
.zuid:before {
  content: "";
  height: 0.5px;
  width: 18px;
  border-top: 1px dashed #ff5467;
  position: absolute;
  left: 0;
  // left: 50%;
  // margin-left: -40%;
  top: 11px;
}
</style>
<style>
.van-step--vertical .van-step__circle-container{
  left: 0;
}
.van-step--vertical .van-step__line{
  left: 0;
}
</style>